export default {
  name: "visitSeparation",
  data() {
    return {
      series: [45, 18, 27, 15, 13],
      chartOptions: {
        chart: {
          type: "donut"
        },
        labels: ["移动端", "PC", "平板", "IOS", "其他"],
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: "right"
            }
          }
        }]
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};